// INFO: AVAILABLE_FEATURES list entails all the available features within Irwin.
// ! Everytime we add support for new feature in Irwin, this list needs to be updated.
// ! Ideally this list would be dynamic and can be fetched from a centralized service.
const AVAILABLE_FEATURES = [
  'irwin_mail',
  'nobo_list',
  'surveillance',
  'multi_security',
  'iq_standalone',
  'feedback',
]

export default AVAILABLE_FEATURES
