import { Box } from '@the-platform-group/component-library'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@the-platform-group/component-library/DataGrid'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'

import { TruncatedText } from 'components/TruncatedText'
import CustomDetailPanelToggle from 'pages/IrwinAssistant/Requests/components/CustomDetailPanelToggle'
import StatusDropdown from 'pages/IrwinAssistant/Requests/components/StatusDropdown'
import AssigneeCell from '../components/AssigneeCell'

function getColumnValues({ currentUser, handleRowChange }) {
  return [
    { field: 'customer' },
    { field: 'userName' },
    { field: 'userEmail' },
    {
      field: 'description',
      width: 280,
      flex: null,
      renderCell: ({ value }) => (
        <Box width="100%">
          <TruncatedText title="" content={value} contentPreview={value} maxLines={2} />
        </Box>
      ),
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field,
      type: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.type,
      valueGetter: GRID_DETAIL_PANEL_TOGGLE_COL_DEF.valueGetter,
      headerName: 'Files',
      width: 280,
      renderCell: params => (
        <CustomDetailPanelToggle isExpanded={params.value} count={params.row.fileCount} />
      ),
    },
    { field: 'createdAt', headerName: 'Request Date', type: DATE, sortable: true },
    {
      field: 'status',
      cellClassName: params => `status-button-${params.value}`,
      renderCell: params => <StatusDropdown onStatusChange={handleRowChange} row={params.row} />,
    },
    {
      field: 'assignee',
      renderCell: params => (
        <AssigneeCell handleRowChange={handleRowChange} params={params} currentUser={currentUser} />
      ),
    },
    {
      field: 'comments',
      width: 280,
      flex: null,
      editable: true,
      renderCell: ({ value }) => (
        <Box width="100%">
          <TruncatedText title="" content={value} contentPreview={value} maxLines={2} />
        </Box>
      ),
    },
  ]
}

const getRowValues = uploadRequests =>
  uploadRequests.map(uploadRequest => {
    const {
      id,
      customer,
      user,
      message,
      filePaths,
      createdAt,
      status,
      assignee,
      comments,
    } = uploadRequest
    const { firstName, lastName, email } = user

    return {
      id,
      customer: customer.name,
      userName: `${firstName} ${lastName}`,
      userEmail: email,
      description: message,
      fileCount: filePaths.length,
      filePaths,
      createdAt,
      status,
      assignee: assignee,
      comments,
    }
  })

export { getColumnValues, getRowValues }
