import { useQuery } from 'react-query'

import { Grid, Typography } from '@the-platform-group/component-library'

import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { getColumnValues, getRowValues } from './IqDigestTableValues'

import { getUserSites } from 'services/sites'

import ToggleSubscriptionSwitch from '../ToggleSubscriptionSwitch'
import CreateSubscriptionSwitch from '../CreateSubscriptionSwitch'

const transformSiteData = (data, subscriptions, notification, user) => {
  return data
    .filter(s => s.enabled)
    .map(site => {
      let siteSub = subscriptions.filter(s => s.configuration.site_id === site.id)
      let hasSubscription = siteSub.length > 0
      let config = { site_id: site.id }
      return {
        id: site.id,
        siteName: site.name,
        configuration: JSON.stringify(hasSubscription ? siteSub[0].configuration : config),
        enabled: hasSubscription ? siteSub[0].enabled : false,
        rowComponent: hasSubscription ? (
          <ToggleSubscriptionSwitch {...siteSub[0]} />
        ) : (
          <CreateSubscriptionSwitch
            notificationId={notification.id}
            userId={user.id}
            customerId={site.customer.id}
            configuration={config}
          />
        ),
      }
    })
}

const IqDigestTable = ({ notification, subscriptions, user }) => {
  const customerIds = user.customerAuthorizations.map(ca => {
    return ca.customer.id
  })

  const { data: userSites, isLoading: sitesLoading } = useQuery(
    ['getUserSites', user.id],
    () => getUserSites({ customerId: customerIds }),
    {
      enabled: !!(customerIds && subscriptions),
      select: data => transformSiteData(data, subscriptions, notification, user),
      placeholderData: () => [],
    },
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{notification.name}</Typography>
        <DataGrid
          name="IqDigestTable"
          columns={getColumnValues()}
          commonColumnProps={{ headerAlign: 'left' }}
          rows={getRowValues(userSites)}
          loading={sitesLoading}
          localeText={{
            noRowsLabel: "No provisioned sites were found for this user's customer.",
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'configuration', sort: 'asc' }],
            },
          }}
          page={0}
          pageSize={50}
          rowCount={userSites.length}
          pageSizeOptions={[10, 25, 50]}
        />
      </Grid>
    </>
  )
}

export default IqDigestTable
